import { Form } from 'react-bootstrap';
import { useState } from 'react';
import NotificationType from '../../../model/NotificationType';
import NotificationToggleMgrLocation from '../../../model/NotifcationToggleMgrLocation';
import ChargingStation from '../../../model/ChargingStation';
import { toggleNotificationLocation } from '../../../service/Api';
import NotificationToggleMgr from '../../../model/NotificationToggleMgr';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { AxiosResponse } from 'axios';
import IncidentNotificationToggleModal from './IncidentNotificationToggleModal';
import DisplayLocation from '../../../model/DisplayLocation';

interface MngrNotificationToggleFormProps {
    refetchNotificationsMgr: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
    setTransactionMessage: React.Dispatch<React.SetStateAction<string>>;
    setShowMessageModal: React.Dispatch<React.SetStateAction<boolean>>;
    notificationToggleMgr: AxiosResponse<any, any> | undefined;
    location: DisplayLocation;
    stations: ChargingStation[];
    types: NotificationType[];
}

export default function MngrNotificationToggleForm(props: MngrNotificationToggleFormProps) {
    const [showNotificationToggleModal, setShowNotificationToggleModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleToggleNotificationLocation = async (stations: ChargingStation[], isMailEnabled: boolean, isPushEnabled: boolean) => {
        try {
            const toggleLocation: NotificationToggleMgrLocation = {
                chargingStations: stations.map((chargingStation: ChargingStation) => chargingStation.key),
                types: props.types,
                enabled: isMailEnabled,
                pushEnabled: isPushEnabled
            };
            setIsDisabled(true);
            await toggleNotificationLocation(toggleLocation);
            props.refetchNotificationsMgr();
            setIsDisabled(false);
        } catch (error) {
            props.setTransactionMessage('Something went wrong, please try again later.');
            props.setShowMessageModal(true);
        }
    };

    const isTypeForThisForm = (type: NotificationType): boolean => {
        return props.types.includes(type);
    };

    const checkNotifications = (stations: ChargingStation[]): boolean => {
        return (
            props.notificationToggleMgr?.data?.some((notificationMgr: NotificationToggleMgr) => {
                return stations.some((chargingStation: ChargingStation) => {
                    return (
                        notificationMgr.chargingStation.key === chargingStation.key &&
                        (notificationMgr.enabled || notificationMgr.pushEnabled) &&
                        isTypeForThisForm(notificationMgr.notificationType)
                    );
                });
            }) || false
        );
    };

    const checkMailNotifications = (stations: ChargingStation[]): boolean => {
        return (
            props.notificationToggleMgr?.data?.some((notificationMgr: NotificationToggleMgr) => {
                return stations.some((chargingStation: ChargingStation) => {
                    return (
                        notificationMgr.chargingStation.key === chargingStation.key &&
                        notificationMgr.enabled &&
                        isTypeForThisForm(notificationMgr.notificationType)
                    );
                });
            }) || false
        );
    };

    const checkPushNotifications = (stations: ChargingStation[]): boolean => {
        return (
            props.notificationToggleMgr?.data?.some((notificationMgr: NotificationToggleMgr) => {
                return stations.some((chargingStation: ChargingStation) => {
                    return (
                        notificationMgr.chargingStation.key === chargingStation.key &&
                        notificationMgr.pushEnabled &&
                        isTypeForThisForm(notificationMgr.notificationType)
                    );
                });
            }) || false
        );
    };

    const handleChange = () => {
        if (checkMailNotifications(props.stations) || checkPushNotifications(props.stations)) {
            handleToggleNotificationLocation(props.stations, false, false);
        } else {
            setShowNotificationToggleModal(true);
        }
    };

    const hasDisabled = (data: NotificationToggleMgr[] = [], types: NotificationType[]): boolean => {
        if (!data || !Array.isArray(data)) return false;
        return data.some(
            (notificationMgr: NotificationToggleMgr) =>
                types.includes(notificationMgr.notificationType) &&
                notificationMgr.enabled === false &&
                props.stations.some((chargingStation: ChargingStation) => chargingStation.key === notificationMgr.chargingStation.key)
        );
    };

    return (
        <>
            <IncidentNotificationToggleModal
                key={props.location + 'incident modal'}
                show={showNotificationToggleModal}
                onHide={() => setShowNotificationToggleModal(false)}
                handleToggleNotificationLocation={handleToggleNotificationLocation}
                stations={props.stations}
                isMailEnabled={checkMailNotifications(props.stations)}
                isPushEnabled={checkPushNotifications(props.stations)}
            />
            <Form.Check
                style={{ marginLeft: '10%' }}
                className={hasDisabled(props.notificationToggleMgr?.data || [], props.types) ? 'halfChecked' : ''}
                type="switch"
                id={props.location.id}
                onChange={handleChange}
                checked={checkNotifications(props.stations)}
                disabled={isDisabled}
            />
        </>
    );
}
