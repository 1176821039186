interface LocationTitleProps {
    locationName: string;
    filter: null | string;
}

export default function LocationTitle(props: LocationTitleProps) {
    let title = props.locationName;

    if (props.filter === null || props.filter === '' || props.filter === 'all') {
        title += ' - Dashboard';
    } else if (props.filter.startsWith('status-')) {
        title += ' - ' + props.filter.replace('status-', '');
    } else if (props.filter === 'session') {
        title += ' - Your Charging Session';
    }

    return (
        <div className="locationTitle">
            <h2>{title}</h2>
        </div>
    );
}
