import React, { useState } from 'react';
import { Table, Accordion } from 'react-bootstrap';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import ArrowUp from '../shared/icon/ArrowUp';
import Clock from '../shared/icon/Clock';
import EvFront from '../shared/icon/EvFront';
import EvStation from '../shared/icon/EvStation';
import UserStatsDetail from './UserStatsDetail';
import CaretDown from '../shared/icon/CaretDown';
import CaretUp from '../shared/icon/CaretUp';
import UserStats from '../../model/stats/UserStats';

interface UserStatDetailProps {
    userStats: UserStats[];
}

function UserStatsTable(props: UserStatDetailProps) {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' }); // Detect small screens
    const [activeKey, setActiveKey] = useState<string | null>(null);

    const toggleRow = (key: string) => {
        setActiveKey(activeKey === key ? null : key);
    };

    if (isSmallScreen) {
        // Render details directly for small screens
        return (
            <>
                {props.userStats.map((stat, index) => (
                    <React.Fragment key={`details-${index}`}>
                        <UserStatsDetail
                            key={`details-${index}`}
                            stat={stat}
                            unmovedDuration={moment.duration(
                                moment.duration(stat.occupationTime).asMinutes() - moment.duration(stat.chargingTime).asMinutes(),
                                'minutes'
                            )}
                            badUnmovedDurationMinutes={30}
                            eventKey={`stat-${index}`}
                            isSmallScreen={isSmallScreen}
                        />
                        <div className="divider" />
                    </React.Fragment>
                ))}
                {props.userStats.length === 0 && (
                    <div className="no-results">
                        <h1>No results</h1>
                    </div>
                )}
            </>
        );
    }

    return (
        <Accordion activeKey={activeKey}>
            <Table className="user-stats-table">
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Session Time</th>
                        <th>Amount Charged</th>
                        <th>Charging Time</th>
                        <th>Occupation Time</th>
                        <th>Occupation Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {props.userStats.map((stat, index) => {
                        const chargingTime = moment.duration(stat.chargingTime);
                        const occupationTime = moment.duration(stat.occupationTime);
                        const unmovedDuration = moment.duration(occupationTime.asMinutes() - chargingTime.asMinutes(), 'minutes');
                        const badUnmovedDurationMinutes = 30;

                        return (
                            <React.Fragment key={`stat-${index}`}>
                                <tr style={{ cursor: 'pointer' }} onClick={() => toggleRow(`stat-${index}`)}>
                                    <td data-label="Location">{stat.displayLocation.name}</td>
                                    <td data-label="Session Time">
                                        {moment(stat.startDate).format('DD/MM HH:mm')} - {moment(stat.endDate).format('HH:mm')}
                                    </td>
                                    <td data-label="Amount Charged">
                                        <ArrowUp />
                                        &nbsp;{stat.kWh} kWh
                                    </td>
                                    <td data-label="Charging Time">
                                        <EvFront />
                                        &nbsp;{moment.duration(stat.chargingTime).humanize()}
                                    </td>
                                    <td
                                        data-label="Occupation Time"
                                        style={unmovedDuration.asMinutes() > badUnmovedDurationMinutes ? { color: 'var(--bs-xploregroup-red)' } : {}}
                                    >
                                        <Clock />
                                        &nbsp;{moment.duration(stat.occupationTime).humanize()}
                                    </td>
                                    <td data-label="Occupation Rate">
                                        <EvStation />
                                        &nbsp;
                                        {Math.max(
                                            stat.occupiedStationsWhenFinishedCharging,
                                            stat.occupiedStationsWhenMoved,
                                            stat.maxOccupiedStationsWhileUnmoved ?? 0
                                        )}
                                        / {stat.totalChargingStations}
                                    </td>
                                    <td className="arrow-cell">{activeKey === `stat-${index}` ? <CaretUp /> : <CaretDown />}</td>
                                </tr>
                                <tr className={`details ${activeKey === `stat-${index}` ? 'expanded' : ''}`}>
                                    <td colSpan={6}>
                                        <Accordion.Collapse eventKey={`stat-${index}`}>
                                            <UserStatsDetail
                                                stat={stat}
                                                unmovedDuration={unmovedDuration}
                                                badUnmovedDurationMinutes={badUnmovedDurationMinutes}
                                                eventKey={`stat-${index}`}
                                                isSmallScreen={isSmallScreen}
                                            />
                                        </Accordion.Collapse>
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                    {props.userStats.length === 0 && (
                        <tr className="no-results">
                            <td colSpan={10}>
                                <h1>No results</h1>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Accordion>
    );
}

export default UserStatsTable;
