import { useEffect, useRef, useState } from 'react';
import useCheckIfAdminOrManager from '../../../hooks/useCheckIfAdminOrManager';
import { Button, ButtonGroup, FormControl, Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasTitle } from 'react-bootstrap';
import useGetUserInfo from '../../../hooks/useGetUserInfo';
import Select from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import useResizingTextArea from '../../../hooks/useResizingTextArea';
import ToastMessage from '../../shared/ToastMessage';
import { sendTemplateNotification } from '../../../service/Api';

interface SendMailFromTemplateProps {
    show: boolean;
    setShow: (show: boolean) => void;
    name: string;
    contactEmail: string;
}

interface EmailTemplate {
    key: number;
    name: string;
    subject: string;
    body: string;
}

interface Option {
    value: string;
    label: string;
    subject: string;
    body: string;
}

export default function SendMailFromTemplate(props: SendMailFromTemplateProps) {
    const userInfo = useGetUserInfo();
    const isAdminOrManager = useCheckIfAdminOrManager();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
    const [toastMessage, setToastMessage] = useState<string | null>(null);

    const templates: EmailTemplate[] = [
        {
            key: 1,
            name: 'You are plugged in but not charging',
            subject: 'You are plugged in but not charging',
            body: 'We noticed that you are currently not charging, while your car is plugged in.  This can happen for various reasons.  Visit our FAQ page at https://charging.xploregroup.be/faq for tips on how to prevent this from happening.'
        },
        {
            key: 2,
            name: 'You are occupying a space without charging',
            subject: 'You are occupying a space without charging',
            body: 'We noticed that your car is parked on a parking space with a charging station but you do not appear to be charging.  Please either plug in your car and start charging or move your car to a regular parking space.'
        },
        {
            key: 3,
            name: 'You are using the wrong charging station',
            subject: 'You are using the wrong charging station',
            body: 'We noticed that your car is parked on the parking spot for charging station {𝙥𝙡𝙖𝙘𝙚𝙝𝙤𝙡𝙙𝙚𝙧}, while it is plugged into charging station {𝙥𝙡𝙖𝙘𝙚𝙝𝙤𝙡𝙙𝙚𝙧}.  Please either move your car to the right parking spot or plug it into the right charging station.'
        },
        {
            key: 4,
            name: 'You are parked incorrectly',
            subject: 'You are parked incorrectly',
            body: 'We noticed that your car is parked incorrectly, please move your car so it is parked within the white lines.'
        },
        {
            key: 5,
            name: 'You are charging with an unregistered fuelcard',
            subject: 'Unregistered fuelcard - Charging suspended',
            body: 'We stopped your charging session at station {𝙥𝙡𝙖𝙘𝙚𝙝𝙤𝙡𝙙𝙚𝙧} because your fuelcard is not registered. Once your fuelcard is registered your session will automatically be restarted. Please register your fuelcard on https://charging.xploregroup.be.'
        },
        {
            key: 6,
            name: 'Empty template',
            subject: '',
            body: ''
        }
    ];

    const options = templates
        .sort((a, b) => a.subject.localeCompare(b.subject))
        .map((template: EmailTemplate) => {
            return { value: template.key, label: template.name, subject: template.subject, body: template.body };
        });

    const customFilterOption = (option: FilterOptionOption<Option>, inputValue: string) => {
        const label = option.data.label.toLowerCase();
        const subject = option.data.subject.toLowerCase();
        const body = option.data.body.toLowerCase();

        const lowerInput = inputValue.toLowerCase();

        return label.includes(lowerInput) || subject.includes(lowerInput) || body.includes(lowerInput);
    };

    const doSendMail = () => {
        if (selectedTemplate == null) {
            setToastMessage('No template selected.  Please select a template and try again.');
            return;
        }

        if (selectedTemplate.subject == null || selectedTemplate.subject.trim() === '') {
            setToastMessage('No subject entered.  Please enter a subject and try again.');
            return;
        }

        if (selectedTemplate.body == null || selectedTemplate.body.trim() === '') {
            setToastMessage('No body entered.  Please enter a body and try again.');
            return;
        }

        if (props.contactEmail === undefined) {
            setToastMessage('No contact email found.');
            return;
        }

        sendTemplateNotification(selectedTemplate.subject, selectedTemplate.body, props.contactEmail)
            .then(() => {
                setToastMessage('Mail sent');
                props.setShow(false);
            })
            .catch((error) => {
                console.error(error);
                setToastMessage('Failed to send mail.  Please try again later.');
            });
    };

    useEffect(() => {
        if (props.show && !isAdminOrManager) {
            props.setShow(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdminOrManager, props.show]);

    useResizingTextArea(textAreaRef.current, selectedTemplate?.body ?? '');

    return (
        <Offcanvas show={props.show} onHide={() => props.setShow(false)} placement="end" style={{ width: '60vw', minWidth: '350px' }}>
            <OffcanvasHeader closeButton>
                <OffcanvasTitle>Send email</OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody style={{ paddingTop: '0' }}>
                <ToastMessage
                    showToastMessage={toastMessage !== null}
                    message={toastMessage ?? ''}
                    setShowToastMessage={(show) => {
                        if (!show) setToastMessage(null);
                    }}
                />
                <div className="row align-items-center">
                    <div className="col-auto" style={{ paddingRight: '0' }}>
                        Select template:
                    </div>
                    <div className="col">
                        <Select
                            classNamePrefix="react-select"
                            className="selectDropdown"
                            onChange={(selection) => {
                                if (selection) {
                                    setSelectedTemplate(templates.find((template) => template.key === Number(selection.value)) ?? null);
                                }
                            }}
                            // @ts-ignore
                            options={options}
                            filterOption={customFilterOption}
                            placeholder="Select from template..."
                            autoFocus
                            styles={{
                                placeholder: (base) => {
                                    return {
                                        ...base,
                                        pointerEvents: 'none',
                                        userSelect: 'none',
                                        MozUserSelect: 'none',
                                        WebkitUserSelect: 'none',
                                        msUserSelect: 'none'
                                    };
                                },
                                input: (base) => ({
                                    ...base,
                                    gridTemplateColumns: '0 minmax(min-content, 1fr)'
                                })
                            }}
                        />
                    </div>
                </div>

                <hr style={{ margin: '2vh 0 1vh 0' }} />

                <p style={{ marginBottom: '0' }}>
                    <span style={{ fontWeight: 'bolder' }}>To: </span>
                    {props.name} ({props.contactEmail})
                </p>
                <p style={{ marginBottom: '0' }}>
                    <span style={{ fontWeight: 'bolder' }}>From: </span>
                    System (noreply.charging@xploregroup.be)
                </p>
                <p style={{ marginBottom: '1vh' }}>
                    <span style={{ fontWeight: 'bolder' }}>CC: </span>
                    You ({userInfo.email})
                </p>

                {selectedTemplate === null ? (
                    <p style={{ fontStyle: 'italic' }}>Please select a template above</p>
                ) : (
                    <>
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <span style={{ fontWeight: 'bolder' }}>Subject:</span>
                            </div>
                            <div className="col">
                                <FormControl
                                    value={selectedTemplate.subject}
                                    onChange={(e) =>
                                        setSelectedTemplate({
                                            ...selectedTemplate,
                                            subject: e.target.value
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <p style={{ margin: '0 0 1vh 0' }}>
                            <span style={{ fontWeight: 'bolder' }}>Body preview:</span>
                        </p>
                        <div
                            style={{
                                border: '#40404B solid 1px',
                                borderRadius: '6px',
                                maxHeight: '58vh',
                                overflowY: 'auto'
                            }}
                        >
                            <p style={{ margin: '1vh 1vw 0 1vw', padding: '0' }}>Hi {props.name},</p>
                            <FormControl
                                as="textarea"
                                ref={textAreaRef}
                                value={selectedTemplate.body}
                                onChange={(e) =>
                                    setSelectedTemplate({
                                        ...selectedTemplate,
                                        body: e.target.value.replace(/\r?\n|\r/g, ' ')
                                    })
                                }
                                style={{
                                    margin: '1vh 0 0 0',
                                    padding: '0 1vw',
                                    height: 'fit-content',
                                    minHeight: '20vh',
                                    resize: 'vertical'
                                }}
                            />
                            <p style={{ margin: '1vh 1vw', padding: '0' }}>
                                Please do not reply to this email. You can manage your notification settings here.
                            </p>
                        </div>

                        <ButtonGroup style={{ width: '100%', margin: '1vh auto' }}>
                            <Button style={{ minWidth: 'fit-content' }} variant="outline-danger" onClick={() => props.setShow(false)}>
                                Cancel
                            </Button>
                            <Button
                                style={{ minWidth: 'fit-content' }}
                                variant="success"
                                onClick={doSendMail}
                                disabled={selectedTemplate.subject.trim() === '' || selectedTemplate.body.trim() === ''}
                            >
                                Send
                            </Button>
                        </ButtonGroup>
                    </>
                )}
            </OffcanvasBody>
        </Offcanvas>
    );
}
