import '../../../styling/announcementManagement.css';
import useCheckIfAdminOrManager from '../../../hooks/useCheckIfAdminOrManager';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getLocations } from '../../../service/Api';
import LoadingSpinner from '../../shared/LoadingSpinner';
import ManageCslAnnouncement from './ManageCslAnnouncement';
import PageTemplate from '../../shared/PageTemplate';
import ApiError from '../../shared/ApiError';
import DisplayLocation from '../../../model/DisplayLocation';

export default function AnnouncementManagementPage() {
    const isAdminOrManager = useCheckIfAdminOrManager();
    const navigate = useNavigate();
    if (!isAdminOrManager) {
        navigate('/dashboard');
    }

    const {
        data: dls,
        isLoading: dlsLoading,
        error: dlsError
    } = useQuery('chargingStationLocations', getLocations, {
        refetchOnWindowFocus: false
    });

    if (dlsLoading) {
        return (
            <PageTemplate>
                <div style={{ padding: '1em' }}>
                    <h1 className="h2">Manage announcements</h1>
                    <LoadingSpinner />
                    <p style={{ textAlign: 'center', marginTop: '1%' }}>Loading locations ...</p>
                </div>
            </PageTemplate>
        );
    } else if (dlsError) {
        return (
            <PageTemplate>
                <div style={{ padding: '1em' }}>
                    <h1 className="h2">Manage announcements</h1>
                    <ApiError
                        error={dlsError}
                        title={'Something went wrong fetching the charging station locations.'}
                        message={
                            <>
                                <p>Something went wrong fetching the charging station locations. Please try again later.</p>
                                <p>If this error persists, please contact the website maintainer.</p>
                            </>
                        }
                    />
                </div>
            </PageTemplate>
        );
    }

    return (
        <PageTemplate className="announcementMngmtPage">
            <h1 className="h2">Manage announcements</h1>
            {dls?.data?.map((dl: DisplayLocation) => <ManageCslAnnouncement key={dl.id} dl={dl} />)}
        </PageTemplate>
    );
}
