import { useQuery } from 'react-query';
import { getFullLeaderboardScores } from '../../service/Api';
import ApiError from '../shared/ApiError';
import LoadingSpinner from '../shared/LoadingSpinner';
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Page from '../../model/Page';
import PaginationButtons from '../shared/PaginationButtons';
import DisplayLocation from '../../model/DisplayLocation';

interface FullWallOfFameProps {
    location: DisplayLocation;
}

export default function FullWallOfFame(props: FullWallOfFameProps) {
    const [page, setPage] = useState<Page>({
        currentPage: 0,
        totalPages: 0,
        isEmpty: true
    });

    const {
        data: leaderBoard,
        isLoading: isLeaderBoardLoading,
        error: leaderBoardError
    } = useQuery(['fullWallOfFame', props.location.name, page.currentPage], () => getFullLeaderboardScores(props.location.name, page.currentPage), {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (leaderBoard) {
            setPage({
                currentPage: leaderBoard.data.number ?? 0,
                totalPages: leaderBoard.data.totalPages ?? 0,
                isEmpty: leaderBoard.data.empty ?? true
            });
        }
    }, [leaderBoard]);

    if (isLeaderBoardLoading) {
        return <LoadingSpinner />;
    } else if (leaderBoardError) {
        return <ApiError error={leaderBoardError} />;
    }

    const getCompanyFromEmail = (email: string): string => {
        if (!email.includes('@')) {
            return '';
        }

        let company = email.split('@')[1];
        company = company.split('.')[0];
        return company.charAt(0).toUpperCase() + company.slice(1);
    };

    return (
        <>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Company</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {leaderBoard?.data?.content.map((userScore: any) => (
                        <tr>
                            <td>
                                {userScore.user.firstName} {userScore.user.lastName}
                            </td>
                            <td>{getCompanyFromEmail(userScore.user.contactEmail)}</td>
                            <td>{userScore.score}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationButtons setPage={setPage} page={page} changingPage={false} />
        </>
    );
}
