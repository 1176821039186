import UserPool from '../../model/UserPool';
import PoolItem from './PoolItem';
import PoolAddButton from './PoolAddButton';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';
import ChargingStationLocation from '../../model/ChargingStationLocation';
import { useEffect, useState } from 'react';
import { getChargingStationLocations } from '../../service/Api';

interface PoolContainerProps {
    userPool: UserPool[];
    setShowAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
    setToastMessage: (message: string) => void;
    refetchPool: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<UserPool[], unknown>>;
}

export default function PoolContainer(props: PoolContainerProps) {
    const [chargingSessions, setChargingSessions] = useState<ChargingStationLocation[]>([]);

    const { data: allCslsData, isLoading: isAllCslsLoading } = useQuery('allCslsWithChargingStations', () => getChargingStationLocations('all'), {
        refetchOnWindowFocus: false,
        refetchInterval: 300000
    });

    useEffect(() => {
        if (allCslsData && allCslsData.data) {
            setChargingSessions(allCslsData.data);
        }
    }, [allCslsData]);

    return (
        <>
            <div className="pool">
                {props.userPool?.length > 0 ? (
                    <>
                        {props.userPool?.map((userPoolItem: UserPool) => {
                            const locationWithStation = chargingSessions
                                .map((location) => ({
                                    location,
                                    station: location.chargingStations.find((station) => station.contactEmail === userPoolItem.email)
                                }))
                                .find((item) => item.station !== undefined);

                            return (
                                <PoolItem
                                    key={userPoolItem.name}
                                    userPoolItem={userPoolItem}
                                    chargingStation={locationWithStation?.station}
                                    isAllCslsLoading={isAllCslsLoading}
                                    location={locationWithStation?.location.displayLocation.name}
                                    {...props}
                                />
                            );
                        })}
                        {props.userPool?.length < 15 && <PoolAddButton {...props} />}
                    </>
                ) : (
                    <div className="empty-pool">
                        <p style={{ fontWeight: '500' }}>
                            It looks like your pool is empty. Click the + button to add friends and start sharing charging spots!
                        </p>
                        <PoolAddButton {...props} />
                    </div>
                )}
            </div>
        </>
    );
}
