import { Modal, Tab, Tabs } from 'react-bootstrap';
import '../../styling/leaderBoard.css';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAllChargingStationLocations } from '../../service/Api';
import LoadingSpinnerPage from '../shared/LoadingSpinnerPage';
import ApiError from '../shared/ApiError';
import PageTemplate from '../shared/PageTemplate';
import { getPreferredCsl } from '../../service/ChargingStationLocationFunctions';
import LocationWallOfFame from './LocationWallOfFame';
import InfoCircle from '../shared/icon/InfoCircle';
import DisplayLocation from '../../model/DisplayLocation';

export default function WallOfFame() {
    const [allLocations, setAllLocations] = useState<DisplayLocation[]>([]);
    const [tab, setTab] = useState('none');

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    const {
        data: allDlsData,
        isLoading: isAllDlsLoading,
        error: allDlsError
    } = useQuery('allDls', () => getAllChargingStationLocations(), {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (!allDlsData) {
            return;
        }
        setAllLocations(allDlsData.data);
        setTab(getPreferredCsl(allDlsData.data).name);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDlsData]);

    if (isAllDlsLoading) {
        return <LoadingSpinnerPage />;
    } else if (allDlsError) {
        return (
            <PageTemplate>
                <ApiError error={allDlsError} />
            </PageTemplate>
        );
    }
    return (
        <PageTemplate className="leaderBoardContainer">
            <h1 className="h2" style={{ margin: '0.2em 0.5em' }}>
                Wall of fame{' '}
                <button className={'btn'} style={{ width: 'fit-content' }} onClick={() => setShowInfoModal(!showInfoModal)}>
                    <InfoCircle />
                </button>
            </h1>
            {
                <Tabs id="locationTab" className={'locationTabs'} activeKey={tab} onSelect={(k) => setTab(k ?? 'none')}>
                    {allLocations.map((loc) => (
                        <Tab title={loc.name} eventKey={loc.name} key={loc.id}>
                            {tab === loc.name ? <LocationWallOfFame location={loc} /> : <></>}
                        </Tab>
                    ))}
                </Tabs>
            }

            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} size={'lg'} fullscreen={'md-down'}>
                <Modal.Header closeButton>
                    <Modal.Title> Wall of fame info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This Wall of Fame page ranks employees and their companies based on how well they move their vehicles after charging.</p>
                    <p>
                        We provide three views of the rankings: an overall company ranking by the average score of employees, a ranking within your
                        own company, and a ranking within your specific pool. These rankings are location-specific, so Kontich and Merelbeke have
                        separate rankings.
                    </p>
                    <p>
                        A person's score is calculated according to several factors: how quickly you move your car after it finishes charging (with
                        bonus points for moving it before it is fully charged), the time of day you move your car (with more points awarded for moving
                        it before noon), the occupancy rate of the charging stations (no points are deducted if enough stations are free), and when
                        your charging session occurs (sessions (or the part of a session that occurs) outside office hours are excluded). Your scores
                        are then totalled, and the rankings are determined based on these totals. We only track sessions from the past month, and to
                        receive a ranking we must have 3 or more sessions.
                    </p>
                </Modal.Body>
            </Modal>
        </PageTemplate>
    );
}
