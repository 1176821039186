import '../../../styling/locationTitle.css';
import LocationTitle from './LocationTitle';
import NotificationSettingsButton from './NotificationSettingsButton';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import ArrowClockwise from '../../shared/icon/ArrowClockwise';
import { useEffect, useState } from 'react';
import { getAverageOccupation } from '../../../service/Api';
import AverageCslOccupation from '../../../model/AverageCslOccupation';
import AverageOccupationChart from './AverageOccupationChart';
import ChargingStation from '../../../model/ChargingStation';
import CaretDown from '../../shared/icon/CaretDown';
import CaretUp from '../../shared/icon/CaretUp';
import DisplayLocation from '../../../model/DisplayLocation';

interface LocationTitleContainerProps {
    locationName: string;
    showNotificationSettings: boolean | null;
    setShowNotificationSettings: React.Dispatch<React.SetStateAction<boolean | null>>;
    filter: null | string;
    refetchChargingStations: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
    isFetchingChargingStations: boolean;
    stationsLastFetched: Date;
    currentLocation: DisplayLocation | undefined;
    chargingStations: ChargingStation[];
}

export default function LocationTitleContainer(props: LocationTitleContainerProps) {
    const [currentTime, setCurrentTime] = useState<Date>(moment().add(10, 's').toDate());
    const [avgOccupation, setAvgOccupation] = useState<AverageCslOccupation[]>([]);
    const [showAvgOccupationGraph, setShowAvgOccupationGraph] = useState<boolean>(false);
    const [dayOfWeek, setDayOfWeek] = useState<number>(new Date().getDay() === 7 ? 0 : new Date().getDay());

    const {
        data: avgOccupationData,
        isLoading: isAvgOccupationDataLoading,
        error: avgOccupationDataError,
        refetch: refetchAvgOccupation
    } = useQuery(['avgOccupation', props.currentLocation?.name], () => getAverageOccupation(props.currentLocation?.name ?? '', dayOfWeek), {
        refetchOnWindowFocus: false,
        enabled: props.currentLocation != null
    });

    useEffect(() => {
        if (dayOfWeek !== -1 && props.currentLocation != null) {
            refetchAvgOccupation();
        }
    }, [dayOfWeek, props.currentLocation, refetchAvgOccupation]);

    useEffect(() => {
        if (isAvgOccupationDataLoading || avgOccupationDataError || !avgOccupationData) {
            setAvgOccupation([]);
            return;
        }

        if (avgOccupationData.data) {
            setAvgOccupation(avgOccupationData.data);
        } else {
            setAvgOccupation([]);
        }
    }, [avgOccupationData, isAvgOccupationDataLoading, avgOccupationDataError]);

    // Effect to update the current time 10 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 10000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const canShowChart = () => {
        return avgOccupation.length !== 0 && getAvgOccupationRateAtCurrentTime() !== undefined && props.chargingStations.length != 0;
    };

    const getCurrentTimeRoundedTo15Minutes = (): string => {
        const date = new Date();
        let hour = date.getHours();
        let minutes = date.getMinutes();
        const roundedMinutes = Math.round(minutes / 15) * 15;

        if (roundedMinutes === 60) {
            minutes = 0;
            hour = (hour + 1) % 24;
        } else {
            minutes = roundedMinutes;
        }

        return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const getAvgOccupationRateAtCurrentTime = () => {
        return avgOccupation.find((avg) => avg.time == getCurrentTimeRoundedTo15Minutes() + ':00');
    };

    function getCurrentCsOccupation() {
        return props.chargingStations.length - props.chargingStations.filter((value) => value.ocppStatus === 'AVAILABLE').length;
    }

    const getAvgOccupationBtnText = () => {
        const current = getAvgOccupationRateAtCurrentTime();
        if (current == undefined) {
            return '';
        }
        const currentOccupation = getCurrentCsOccupation();

        if (current.average > currentOccupation + 2) {
            return 'Less busy than usual';
        } else if (current.average < currentOccupation - 2) {
            return 'Busier than usual';
        } else {
            return 'Average occupation rate';
        }
    };

    return (
        <>
            <div className="locationTitleContainer">
                <LocationTitle {...props} />
                <div className="buttons">
                    <button
                        hidden={!canShowChart()}
                        className="btn avgOccupationIndicator"
                        onClick={() => setShowAvgOccupationGraph(!showAvgOccupationGraph)}
                    >
                        {getAvgOccupationBtnText()} {showAvgOccupationGraph ? <CaretUp /> : <CaretDown />}
                    </button>

                    {props.isFetchingChargingStations ? (
                        <button className="refetchIndicator btn refetchIndicatorLoading">
                            <ArrowClockwise />
                        </button>
                    ) : (
                        <button className="refetchIndicator btn" onClick={() => props.refetchChargingStations()}>
                            {moment(props.stationsLastFetched).from(
                                moment(currentTime).isAfter(props.stationsLastFetched) ? currentTime : moment().add(10, 's').toDate()
                            )}
                            &nbsp;
                            <ArrowClockwise />
                        </button>
                    )}

                    <NotificationSettingsButton {...props} />
                </div>
            </div>
            {showAvgOccupationGraph && canShowChart() ? (
                <AverageOccupationChart
                    avgOccupation={avgOccupation}
                    currentAvg={getAvgOccupationRateAtCurrentTime()}
                    currentOccupation={getCurrentCsOccupation()}
                    currentTime={getCurrentTimeRoundedTo15Minutes()}
                    maxOccupation={props.chargingStations.length}
                    setDayOfWeek={setDayOfWeek}
                />
            ) : (
                <></>
            )}
        </>
    );
}
