import '../../../styling/fuelcardSearch.css';
import useCheckIfAdminOrManagerForLocation from '../../../hooks/useCheckIfAdminOrManagerForLocation';
import { useQuery } from 'react-query';
import { createAnnouncement, deleteAnnouncement, getAnnouncements } from '../../../service/Api';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { Button, Form } from 'react-bootstrap';
import { FormEvent, useEffect, useState } from 'react';
import Announcement from '../../../model/Announcement';
import AnnouncementBanner from '../../shared/AnnouncementBanner';
import ApiError from '../../shared/ApiError';
import DisplayLocation from '../../../model/DisplayLocation';

interface ManageCslAnnouncementProps {
    dl: DisplayLocation;
}

export default function ManageCslAnnouncement(props: ManageCslAnnouncementProps) {
    const isAdminOrManager = useCheckIfAdminOrManagerForLocation(props.dl.name);
    const [originalAnnouncement, setOriginalAnnouncement] = useState<Announcement | null>(null);
    const [newAnnouncement, setNewAnnouncement] = useState<Announcement>({
        id: null,
        message: '',
        locationName: props.dl.name,
        outOfService: false
    });
    const [updated, setUpdated] = useState<boolean>(false);

    const {
        data: announcementData,
        isLoading: isAnnouncementLoading,
        error: announcementError
    } = useQuery(['locationAnnouncement', props.dl.name, updated], getAnnouncements, {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (!isAnnouncementLoading) {
            const fetchedData = announcementData?.data;
            if (Array.isArray(fetchedData)) {
                const cslAnnouncement = fetchedData.find((ann) => ann.locationName === props.dl.name) || null;
                setOriginalAnnouncement(cslAnnouncement);
                setNewAnnouncement(
                    cslAnnouncement || {
                        id: null,
                        message: '',
                        locationName: props.dl.name,
                        outOfService: false
                    }
                );
            }
        }
    }, [isAnnouncementLoading, announcementData, props.dl]);

    if (!isAdminOrManager) {
        return <></>;
    } else if (isAnnouncementLoading) {
        return (
            <div style={{ width: '100%' }}>
                <LoadingSpinner />
                <p style={{ width: '100%', textAlign: 'center' }}>Loading announcement for {props.dl.name}</p>
            </div>
        );
    } else if (announcementError) {
        return (
            <ApiError
                error={announcementError}
                title={<>Error while loading announcement for {props.dl.name}</>}
                message={
                    <p>
                        Error while loading announcement for {props.dl.name}, please try again later. If this error persists, please contact the
                        website maintainer.
                    </p>
                }
            />
        );
    }

    const doCreate = async (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await createAnnouncement(newAnnouncement);
        setUpdated(!updated);
    };
    const doDelete = async (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await deleteAnnouncement(props.dl.name);
        setUpdated(!updated);
    };

    return (
        <div className="announcementMngmtItem">
            <h2 className="h4">Manage {props.dl.name} announcement</h2>
            <Form>
                <Form.Control
                    placeholder="enter an announcement"
                    as="textarea"
                    value={newAnnouncement.message}
                    onChange={(e) =>
                        setNewAnnouncement({
                            ...newAnnouncement,
                            message: e.target.value.replace(/(\r\n|\n|\r)/gm, '')
                        })
                    }
                    disabled={originalAnnouncement != null}
                    hidden={originalAnnouncement != null}
                />
                <h3
                    style={{
                        width: '100%',
                        margin: '1em auto 0 auto',
                        textAlign: 'center',
                        fontSize: 'medium'
                    }}
                    hidden={originalAnnouncement != null || newAnnouncement.message === ''}
                >
                    Preview:
                </h3>

                <AnnouncementBanner
                    show={originalAnnouncement != null || newAnnouncement.message != ''}
                    announcement={newAnnouncement.message != '' ? newAnnouncement : originalAnnouncement}
                />

                <Form.Check
                    type="switch"
                    label="Is out of service announcement"
                    style={{ margin: '1em auto 0 auto', width: 'fit-content' }}
                    onChange={(e) =>
                        setNewAnnouncement({
                            ...newAnnouncement,
                            outOfService: e.target.checked
                        })
                    }
                    checked={newAnnouncement.outOfService}
                    disabled={originalAnnouncement != null}
                />

                <div className="announcementBtnContainer">
                    {originalAnnouncement == null ? (
                        <Button type="submit" variant="outline-primary" onClick={doCreate} onSubmit={doCreate}>
                            Create
                        </Button>
                    ) : (
                        <Button type="submit" variant="outline-danger" onClick={doDelete} onSubmit={doDelete}>
                            Delete
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    );
}
